import React, { Suspense, useEffect, useState } from "react";
import "./App.scss";
import Footer from "./containers/Footer";
import { ToastContainer } from "react-toastify";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import ProtectedRoutes from "./routing/ProtectedRoutes";
import PublicRoutes from "./routing/PublicRoutes";
import { useDispatch, useSelector } from "react-redux";
import { getAppDetails } from "./redux/app/actions";
import PublicSportsBook from "./views/pages/privatePages/sportsBook";
import ContactUs from "./views/pages/privatePages/FooterPages/ContactUs";
import PrivacyPolicy from "./views/pages/privatePages/FooterPages/PrivacyPolicy";
import ResponsibleGame from "./views/pages/privatePages/FooterPages/ResponsibleGame";
import FairPlay from "./views/pages/privatePages/FooterPages/FairPlay";
import GamesRule from "./views/pages/privatePages/FooterPages/GamesRule";
import Terms from "./views/pages/privatePages/FooterPages/Terms";
import BeforeLoginFooter from "./containers/BeforeLoginFooter";
import BeforeContactUs from "./views/pages/publicPages/FooterPages/BeforeContactUs";
import BeforeFairPlay from "./views/pages/publicPages/FooterPages/BeforeFairPlay";
import BeforeGamesRule from "./views/pages/publicPages/FooterPages/BeforeGamesRule";
import BeforePrivacyPolicy from "./views/pages/publicPages/FooterPages/BeforePrivacyPolicy";
import BeforeResponsibleGame from "./views/pages/publicPages/FooterPages/BeforeResponsibleGame";
import BeforeTerms from "./views/pages/publicPages/FooterPages/BeforeTerms";
import TestSportsBook from "./views/pages/privatePages/sportsBook/testSportsBook";
import DepositGateway from "./views/pages/privatePages/deposit/depositGateway";
import GatewaysList from "./views/pages/privatePages/deposit/gatewaysList";
import VIPLevel from "./views/pages/privatePages/vipLevel";
import Promotions from "./views/pages/privatePages/promotions";
import LuckyDraw from "./views/pages/privatePages/luckyDraw";
import IosInstruction from "./views/pages/publicPages/iosInstruction";
import Loader from "./assets/images/loader.webp";
import ScrollToTop from "./ScrollToTop";
// import Referral from "./views/pages/privatePages/referral";
import { APP_CONST, BLOCKED_SESSION_KEY } from "./config/const";
import LiveCasino from "./views/pages/privatePages/livecasino";
import Dashboard from "./views/pages/privatePages/dashboard";
import Casino from "./views/pages/privatePages/casino";
import { getParsedSessionStorage } from "./utils/sessionStorageUtils";
import GlobalUpdatePasswordModal from "./views/pages/privatePages/mandatoryUpdatePassword";
import usePasswordFlagHook from "./hooks/usePasswordFlagHook";
import AtlasLobby from "./views/pages/privatePages/sportsBook/atlasLobby";

const Home = React.lazy(() => import("./views/pages/privatePages/home"));
const Games = React.lazy(() => import("./views/pages/privatePages/games"));
const DynamicCasinoPage = React.lazy(() =>
  import("./views/pages/privatePages/games/DynamicCasinoPage")
);
const Cashier = React.lazy(() => import("./views/pages/privatePages/cashier"));
const LandingPage = React.lazy(() =>
  import("./views/pages/publicPages/landingPage")
);
const Deposit = React.lazy(() => import("./views/pages/privatePages/deposit"));
// const Withdraw = React.lazy(() =>
//   import("./views/pages/privatePages/withdraw")
// );

// const MobileNumber = React.lazy(() =>
//   import("./views/pages/publicPages/register/mobileNumber")
// );
const Profile = React.lazy(() => import("./views/pages/privatePages/profile"));
const Sports = React.lazy(() => import("./views/pages/privatePages/sports"));
const ExchangeLobbyPage = React.lazy(() =>
  import("./views/pages/privatePages/exchange/ExchangeLobbyPage")
);
const MatchDetailPage = React.lazy(() =>
  import("./views/pages/privatePages/sports/matchDetailPage")
);
const MyBets = React.lazy(() => import("./views/pages/privatePages/myBets"));
const WithdrawList = React.lazy(() =>
  import("./views/pages/privatePages/withdraw/WithdrawList")
);
// const ForgotPassword = React.lazy(() =>
//   import("./views/pages/publicPages/forgotPassword")
// );
// const ResetPassword = React.lazy(() =>
//   import("./views/pages/publicPages/forgotPassword/resetPassword")
// );
const Betby = React.lazy(() => import("./views/pages/privatePages/betby"));
const Maintenance = React.lazy(() =>
  import("./views/pages/publicPages/Maintenance")
);
const Register = React.lazy(() => import("./views/pages/publicPages/register"));
const Login = React.lazy(() => import("./views/pages/publicPages/login"));
// const MobileNumberVerify = React.lazy(() =>
//   import("./views/pages/publicPages/register/mobileNumberVerify")
// );

const BlockedRegion = React.lazy(() =>
  import("./views/pages/publicPages/blockedRegion")
);

const App = () => {
  const { isAuth } = useSelector((state) => state.auth);
  const { appDetails, organisationStatus } = useSelector((state) => state.app);
  usePasswordFlagHook(isAuth);

  const dispatch = useDispatch();

  useEffect(() => {
    document.title = appDetails?.SEO_TITLE || "";
    document.canonical = appDetails?.LOGO_URL;
    // check organisation status
    if (
      organisationStatus !== null &&
      organisationStatus !== APP_CONST.ORGANISATION_STATUS.Live &&
      window.location.pathname.indexOf("maintenance") < 0
    ) {
      window.location.href = "/maintenance";
    }
  }, [appDetails]);

  const setVhUnit = () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  };

  useEffect(() => {
    dispatch(getAppDetails());
    setVhUnit();
    window.addEventListener("resize", setVhUnit);
    const locationPersistData = getParsedSessionStorage(
      BLOCKED_SESSION_KEY,
      () => {
        if (window.location.pathname.indexOf("restricted-region") < 0) {
          window.location.href = "/restricted-region";
        }
      },
      true
    );
    if (
      window.location.pathname.indexOf("restricted-region") < 0 &&
      locationPersistData !== null &&
      locationPersistData?.isBlock === true
    ) {
      window.location.href = "/restricted-region";
      // validateOrigin(setErrorMessage, "website");
      // checkForLocationPermissions(setErrorMessage);
    }

    return () => {
      window.removeEventListener("resize", setVhUnit);
    };
  }, []);

  return (
    <Router>
      <ScrollToTop />
      <ToastContainer
        closeButton={false}
        position="top-right"
        autoClose={1000}
      />
      <Suspense
        fallback={
          <div className="loaderImg">
            <img alt="Loader Image" src={Loader} />
          </div>
        }
      >
        <Routes>
          <Route
            path="/"
            element={
              <PublicRoutes isAuth={isAuth}>
                <LandingPage />
                <BeforeLoginFooter />
              </PublicRoutes>
            }
          />
          <Route
            path="/update-password"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <GlobalUpdatePasswordModal />
              </ProtectedRoutes>
            }
          />
          <Route path="/restricted-region" element={<BlockedRegion />} />
          <Route
            path="/maintenance"
            element={<Maintenance organisationStatus={organisationStatus} />}
          />
          <Route
            path="/home"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Home />
                <Footer />
              </ProtectedRoutes>
            }
          />
          <Route path="/sportsbook" element={<AtlasLobby isAuth={isAuth} />} />
          <Route
            path="/games-all"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Games />
                <Footer />
              </ProtectedRoutes>
            }
          />
          <Route path="/vip-level" element={<VIPLevel />} />
          <Route path="/promotions" element={<Promotions />} />
          <Route path="/lucky-draw" element={<LuckyDraw />} />
          <Route path="/ios-instruction" element={<IosInstruction />} />
          <Route
            path="/casino/:casinoslug"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <DynamicCasinoPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/casinogames"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Casino />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/sportsbook"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <PublicSportsBook />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/casino/:casinoslug/:gameslug"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <DynamicCasinoPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/cashier"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Cashier />
                <Footer />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/withdrawList"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <WithdrawList />
                <Footer />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/mybets"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <MyBets />
                <Footer />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/deposit/:gateway"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <DepositGateway />
                {/* <BottomNav /> */}
              </ProtectedRoutes>
            }
          />
          <Route
            path="/gatewaylist"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <GatewaysList />
                <Footer />
              </ProtectedRoutes>
            }
          />{" "}
          <Route
            path="/deposit"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Deposit />
                <Footer />
              </ProtectedRoutes>
            }
          />
          {/* <Route
            path="/exchange"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Sports isAuth={isAuth} />
              </ProtectedRoutes>
            }
          /> */}
          {/* <Route
            path="/withdraw"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Withdraw />
                <Footer />
              </ProtectedRoutes>
            }
          /> */}
          {/* <Route
            path="/mobile-number"
            element={
              <PublicRoutes isAuth={isAuth}>
                <MobileNumber />
              </PublicRoutes>
            }
          /> */}
          <Route
            path="/profile"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Profile />
                <Footer />
              </ProtectedRoutes>
            }
          />
          <Route path="test" element={<Sports />} />
          <Route
            // path="/sports/exchange"
            path="/sports/:gameId"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <ExchangeLobbyPage />
              </ProtectedRoutes>
            }
          />
          <Route path="/betby" element={<Betby isAuth={isAuth} />} />
          <Route
            path="/detail-page/:slug/:matchId/:marketId/:sportId"
            element={
              <>
                <MatchDetailPage isAuth={isAuth} />
                {isAuth ? <Footer /> : <BeforeLoginFooter />}
              </>
            }
          />
          {/* <Route
            path="/referral"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Referral />
              </ProtectedRoutes>
            }
          /> */}
          <Route
            path="/contactus"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <ContactUs />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/privacypolicy"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <PrivacyPolicy />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/responsiblegame"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <ResponsibleGame />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/fairplay"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <FairPlay />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/gamesrule"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <GamesRule />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/terms"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Terms />
              </ProtectedRoutes>
            }
          />
          {/* <Route path="/contactus" element={<ContactUs />} /> */}
          <Route
            path="/contactuss"
            element={
              <PublicRoutes isAuth={isAuth}>
                <BeforeContactUs />
              </PublicRoutes>
            }
          />
          <Route
            path="/privacypolicys"
            element={
              <PublicRoutes isAuth={isAuth}>
                <BeforePrivacyPolicy />
              </PublicRoutes>
            }
          />
          <Route
            path="/fairplays"
            element={
              <PublicRoutes isAuth={isAuth}>
                <BeforeFairPlay />
              </PublicRoutes>
            }
          />
          <Route
            path="/responsiblegames"
            element={
              <PublicRoutes isAuth={isAuth}>
                <BeforeResponsibleGame />
              </PublicRoutes>
            }
          />
          <Route
            path="/gamesrules"
            element={
              <PublicRoutes isAuth={isAuth}>
                <BeforeGamesRule />
              </PublicRoutes>
            }
          />
          <Route
            path="/termss"
            element={
              <PublicRoutes isAuth={isAuth}>
                <BeforeTerms />
              </PublicRoutes>
            }
          />
          <Route
            path="/sportsbooktest"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <TestSportsBook />
              </ProtectedRoutes>
            }
          />
          {/* <Route
            path="/forgot-password"
            element={
              <PublicRoutes isAuth={isAuth}>
                <ForgotPassword />
              </PublicRoutes>
            }
          /> */}
          {/* <Route
            path="/reset-password"
            element={
              <PublicRoutes isAuth={isAuth}>
                <ResetPassword />
              </PublicRoutes>
            }
          /> */}
          <Route
            path="/register"
            element={
              <PublicRoutes isAuth={isAuth}>
                <Register />
                <Footer />
              </PublicRoutes>
            }
          />
          <Route
            path="/sign-in"
            element={
              <PublicRoutes isAuth={isAuth}>
                <Login />
              </PublicRoutes>
            }
          />
          {/* <Route
            path="/verify-number"
            element={
              <PublicRoutes isAuth={isAuth}>
                <MobileNumberVerify />
              </PublicRoutes>
            }
          /> */}
          <Route
            path="/livecasino"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <LiveCasino />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/dashboard"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Dashboard />
              </ProtectedRoutes>
            }
          />
          <Route path="*" exact={true} element={<Navigate to="/" />} />
        </Routes>
      </Suspense>
    </Router>
  );
};

export default App;
