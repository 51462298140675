import { APK_ACCESS_KEY } from "../config/const";

/**
 *
 * @returns {boolean}
 */
export const isApkEnvironment = () => {
  const key = localStorage.getItem(APK_ACCESS_KEY) || null;
  if (!key) {
    return false;
  }
  try {
    const decryptedValue = JSON.parse(window.atob(key));
    if (
      decryptedValue?.apk === true &&
      decryptedValue?.key === process.env.REACT_APP_APK_KEY
    ) {
      return true;
    }
    return false;
  } catch (err) {
    return false;
  }
};
