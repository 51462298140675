import React, { PureComponent } from "react";
import BeforeLoginFooter from "../../../../containers/BeforeLoginFooter";
import Header from "../../../../containers/Header";
import { useNavigate } from "react-router-dom";
import BackIcon from "../../../../assets/svg/BackIcon";

function BeforeTerms() {
  const navigate = useNavigate();
  return (
    <>
      <Header />

      <main className="main static-page">
        <div className="container">
          <div className="headingSec">
            <h4> Terms & Conditions</h4>
            <div className="back-link" onClick={() => navigate(-1)}>
              <BackIcon /> Back
            </div>
          </div>

          <div className="content">
            <h4>1. Introduction</h4>
            <p>
              These terms and conditions governs the rights between Golden
              Gaming International Private Limited, a company incorporated under
              the Companies Act, 2013 with Corporate Identification Number (CIN)
              U74120UP2013PTC059053 and having its office at 31A, First Floor,
              Lounge Building, Zero Point, Development Area, Gangtok,
              Sikkim-737101, its subsidiaries, affiliates, licensors, associates
              and partners, hereinafter referred to as “The Company”, “Golden
              Gaming”, “we”, “us”, “our” and such other adjectives and you (the
              user) hereinafter referred to as ‘you’, ‘user’, ‘your’, ‘player’,
              ‘participants’ in relation to the use of products and/or services,
              hereinafter referred to as “Services” through its website
              www.GoldenGames24x7.com and the Golden Games 24x7 App on both
              android and iOS devices, hereinafter collectively referred to as
              the “Portal” are all subject to and governed by these Terms and
              Conditions, Privacy Policy, including any subsections in the
              Portal, hereinafter referred to as “Terms”.
            </p>
            <p>
              The terms and conditions must be read by you (user) completely and
              well understood before agreeing to the said terms of usage,
              service governing any and all relationship with the products and
              services offered by the Portal. You understand that the Terms will
              be binding on you and agree that Services offered on the Portal
              can be accessed only in accordance with the Terms and it shall be
              your duty to comply with the Terms at all times. You are
              responsible to be aware of and agree to abide by the Terms as
              published and periodically amended or modified by Golden Gaming
              International Limited.
            </p>

            <h4>2. Legality</h4>
            <p>
              We are licensed vide License No. 02/FIN/DSSL/III/472/2014-15 by
              the Directorate of State Lotteries, Government of Sikkim to offer
              online games, including games of chance and sports gaming on
              sporting events within the State of Sikkim in accordance with the
              Sikkim Online Gaming (Regulation) Act, 2008 and Rules framed
              thereunder. All our games are offered in accordance with the said
              Act and Rules.
            </p>
            <p>
              This website is meant only for users who are physically present in
              the State of Sikkim and users having any valid photo identity
              card, i.e., any Voter Identity Card, Aadhar Card or Passport
              issued from outside the State of Sikkim.
            </p>

            <h4>3. Intellectual Property</h4>
            <p>
              Golden Gaming International Private Limited hold all rights
              tangible and intangible rights to the Portal including (but not
              limited to) games, contests, advertisements, written content,
              photographs, graphics, images, illustrations, marks, logos, audio
              or video clippings, flash animation and all the services offered
              on the website including but not limited to all games and other
              content, promotional material, inter alia all strategic and
              development plans, ideas, designs, financial conditions, betting
              odds, data banks, information related to technology and process,
              the technology developed including the software and methodology of
              usage, hereinafter collectively referred to as “Intellectual
              Property”.
            </p>
            <p>
              You (the user) are granted a personal, non-exclusive,
              non-assignable and non-transferrable license to use the Content
              solely for the purposes of accessing and using the Services and
              for no other purposes whatsoever. The user shall not sublicense,
              assign or transfer the license granted, or shall rent / lease or
              any part of such license or of the Content included in such
              license. No transfer, copy, reproduction, distribution,
              exploitation, disassembling, translating, decoding, altering or
              make derivations from or make any other use of the Content on the
              Portal in any manner other than as permitted for obtaining
              Services on the Portal.
            </p>
            <p>
              No right, title or interest in any downloaded materials or
              software is transferred to you by downloading and you are
              expressly prohibited from using such materials for any commercial
              purposes unless agreed with us in writing. All other trademarks
              are the property of their respective owners.
            </p>

            <h4>4. Usage of Golden Games24x7</h4>
            <p>
              Any person accessing the services or otherwise offered by Golden
              Gaming on the Portal for the purpose of participating in in online
              games or sports gaming made available on the Golden Games24x7
              portal, hereinafter referred to as “Contest(s)” shall be bound by
              these Terms and Conditions, and all other rules, regulations, and
              terms of use referred to herein or provided by Golden in relation
              to our services.
            </p>
            <p>
              Golden Gaming shall have all the rights to modify or change the
              already defined Terms and Conditions, rules, regulations, and
              terms of use referred to herein or provided by Golden Gaming in
              relation to Golden Gaming services at any point in time, by
              posting the same on this website. Use of our portal constitutes
              the User’s acceptance and agreement of such Terms and Conditions,
              rules, regulations, and terms of use referred to herein or
              provided by Golden Gaming in relation our services, as amended
              from time to time. Golden Gaming may, at its sole discretion,
              notify the User of any modification or amendment in these Terms
              and Conditions, rules, regulations, and terms of use referred to
              herein or provided by Golden Gaming in relation to Golden Gaming
              services by sending an email to the User's registered email
              address or posting notifications in the User accounts.
            </p>
            <p>
              Certain services offered on our portal might be subject to
              additional rules and regulations formulated in that respect. To
              that extent that these Terms and Conditions are inconsistent with
              the additional conditions formulated, the additional conditions
              shall prevail.
            </p>
            <p>Golden Gaming may, at its sole and absolute discretion:</p>
            <ul className="ps-3 ps-md-4 mb-3">
              <li>
                (a) Restrict, suspend, or terminate any User’s access to all or
                any part of Golden Gaming;
              </li>
              <li>
                (b) Change, suspend, or discontinue all or any part of the
                Golden Gaming Portal Services;
              </li>
              <li>
                (c) Deactivate or delete a User’s account and all related
                information and files on the account;
              </li>
              <li className="ps-4">
                Reject, move, or remove any material that may be submitted by a
                User;
              </li>
              <li>
                (d) Move or remove any content that is available on Golden
                Gaming;
              </li>
              <li>
                (e) Establish general practices and limits concerning use of
                Golden Gaming Portal;
              </li>
              <li>
                (f) Revise or make additions to the roster of players available
                for selection in a Contest on account of revisions to the roster
                of players involved in the relevant Sports Event; and
              </li>
              <li>
                (g) Assign its rights and liabilities to all User accounts
                hereunder to any entity
              </li>
            </ul>
            <p>
              Golden Gaming holds all the rights to restrict, suspend or
              terminate a user’s access to all or any part of Golden Gaming
              provided by Golden Gaming, deactivate or delete user account and
              information, delete user content without any prior notice to the
              user if any user is found or believed to be breaching the Terms
              and Conditions, fair play rules and regulations or is involved in
              manipulation, collusion or fraud. Further, Golden Gaming is
              authorized to take any technical or legal action against the user
              if deems necessary.
            </p>
            <p>
              The users consent to receive communications including
              announcements, administrative messages, and advertisements from
              Golden Gaming or any of its partners, licensors or associates.
            </p>

            <h4>5. TDS Policy</h4>
            <p>
              As per the provision of the Finance Act, 2023, the following Tax
              Deducted at Source (TDS) policy will be applicable to all the
              users of Golden Gaming.
            </p>
            <ul className="ps-3 ps-md-4 mb-3">
              <li>
                i. TDS will be applicable at the time of withdrawal by the user
                from his/her wallet.
              </li>
              <li>
                ii. TDS will be deducted @ 30% on positive "Net Winning"* at the
                time of withdrawal.
              </li>
              <li>
                iii. TDS is also applicable at the end of every financial year
                on 31st March closing wallet balance by considering it as
                withdrawal amount by the user. After applying TDS on the balance
                of, the remaining amount will be carried forward to the next
                financial year as a deposit balance and TDS would not be
                applicable on the same (however, TDS deducted at the end of
                financial year on net winning will not be reimbursed by the
                company).
              </li>
            </ul>
            <p>Net Winning:</p>
            <ul className="ps-3 ps-md-4 mb-3">
              <li>- Cumulative withdrawals during the year</li>
              <li>– Cumulative amount deposited by the user during the year</li>
              <li>– Deposit balance at the beginning of the year</li>
              <li>– Winning balance at the end of the preceding year</li>
              <li>– Total Invested amount in running matches.</li>
            </ul>
            <p>Example:</p>
            <p className="ps-3 ps-md-4">
              In case your Net Winning is more than 0, then 30% will be deducted
              on the Net Winning at the time of withdrawal
            </p>
            <p>Scenario: - 1</p>
            <p className="ps-3 ps-md-4">
              A User has a balance Rs. 5000 in the deposit section and Rs.
              10,000 winning section on 01st April 2023. He invested Rs. 4000 to
              play a game and won Rs. 8000. So his/her Net winning shall be Rs.
              8000 – Rs. 15,000 = (- Rs. 7000). If the user withdraws Rs. 8000
              then we will not deduct TDS Since user Net Winning is less than 0.
            </p>
            <p>Scenario: - 2</p>
            <p className="ps-3 ps-md-4">
              A User has a balance Rs. 5000 in the deposit section and Rs.
              10,000 winning section on 01st April 2023. He invested again Rs.
              6000 to play a game and won Rs. 10000. If a user withdraws Rs.
              10,000 then we will calculate his net winning as follows:- So
              his/her Net winning will be Rs. 18000 – Rs. 15,000 = (Rs. 3000).
              TDS will be deducted @ 30% on Rs. 3000 i.e. Rs. 900
            </p>
            <p>Scenario: - 3</p>
            <p className="ps-3 ps-md-4">
              A User has a balance Rs. 5000 in the deposit section and Rs.
              10,000 winning section on 01st April 2023. He invested Rs. 5000 to
              play a game and won Rs. 10000. If the user withdraws Rs. 10,000
              then we will calculate his net winning as follows:-
            </p>
            <p className="ps-3 ps-md-4">
              So, the User’s Net winning will be Rs. 28,000 – Rs. 15,000 = (Rs.
              13,000). TDS will be deducted @ 30% on Rs. 13000 i.e. Rs. 3900.
              Since we had already deducted Rs. 900, this time we will deduct
              only Rs. 3000.
            </p>

            <h4>6. GST Policy</h4>
            <p>
              The deposits made by You in your user wallet is inclusive of
              applicable 28% Goods and Services Tax (GST), which the Company
              shall deposit on Your behalf to the government. To review the
              deducted and reimbursed 28% GST amount on the Golden Gaming
              platform, you can access the "My Transactions" page on the Golden
              Gaming website or mobile application.
            </p>

            <h4>7. Privacy Policy</h4>
            <p>
              All information collected from users, such as registration and
              payment information, is subject to Golden Gaming’s Privacy Policy
              which is available at our Privacy Policy page.
            </p>

            <h4>8. User Representations</h4>
            <p>
              Any information provided by you (the User) to us, whether at the
              stage of registration or during anytime subsequently, should be
              authentic, correct and accurate.
            </p>
            <p>
              You represent that you are 18 years of age or older to participate
              in any Contest(s), are physically located in the state of Sikkim,
              possessing any valid Photo Identity Card issued from outside the
              State of Sikkim and are also otherwise competent to enter into
              transactions with other users and us. You are aware that
              participation in the Contest(s) organized by us, hereinafter
              referred to as "Activity" may result in financial loss to you.
              With full knowledge of the facts and circumstances surrounding
              this Activity, you are voluntarily participating in the Activity
              and assume all responsibility and risk resulting from your
              participation, including all risk of financial loss. You agree to
              indemnify and hold us, our employees, directors, officers, and
              agents harmless with respect to any and all claims and costs
              associated with your participation in the Activity.
            </p>

            <h4>9. User Registration</h4>
            <p>
              In order to register for the Contest(s), User(s) are required to
              provide the following information accurately:
            </p>
            <ul className="ps-3 ps-md-4 mb-3">
              <li>Full Name</li>
              <li>E-mail Address</li>
              <li>Password</li>
              <li>Complete Address</li>
              <li>Gender</li>
              <li>Date of Birth</li>
            </ul>
            <p>
              The “Full Name” and “Date of Birth” mentioned by the User for the
              purpose of verification should match with the Name and Date of
              Birth mentioned on all the documents required to be uploaded for
              verification purposes.
            </p>
            <p>
              The User participating in activities, services or features
              provided by Golden Gaming are required to provide their approval
              stating they have carefully read all the terms and conditions and
              will comply with all the rules, regulations, and terms of use
              referred to herein or provided by Golden Gaming in relation to
              Golden Gaming services.
            </p>
            <p>
              On registering on the Portal, a user confirms that he/she is
              physically located in the state of Sikkim and shall only access
              the services/Contest(s) from the territorial boundaries of the
              state of Sikkim. In the event a user a user is found to be
              accessing the portal from outside the territorial boundaries od
              the state of Sikkim, Golden Gaming at its sole and absolute
              discretion holds the rights to take action against such user
              including (but not limited to) blocking of user account, deletion
              or deactivation of user account, and resetting the user account to
              initial level by deleting all the logs, refers & reducing the
              amount present in the user wallet to “zero”.
            </p>
            <p>
              In case of PAN card verification, it is mandatory to upload a full
              image of the original PAN card. No scanned image of a photocopy of
              the users’ PAN card will be acceptable for verification purposes.
            </p>
            <p>
              In case, you are uploading an E-PAN card for PAN card verification
              process then it is mandatory to upload a full scanned image of the
              E-PAN card (both front and back).
            </p>
            <p>
              Golden Gaming reserves the right to disallow you from logging into
              the Portal or reduce your play limits until we are able to
              satisfactorily validate your user account. Golden Gaming will in
              such events email you to notify you of the next steps regarding
              user account validation. We may also ask you for proof of
              identification and proof of address from time to time.
            </p>

            <h4>10. User Restrictions</h4>
            <p>
              The User using Services provided by Golden Gaming should not use,
              take the aid of or assist in using, either directly or indirectly
              any automation, software, bots, hacks or any unauthorized
              third-party software for participating in any contest or for
              modifying or intervening with GoldenGames24x7.com and/or Golden
              Gaming experience.
            </p>
            <p>
              The User further undertakes to adhere to Golden Gaming’s fairplay
              and responsible gaming policies and not engage or attempt to
              engage in any form of cheating, manipulation, rigging, collusion
              or any other form of unethical or illegal gameplay.
            </p>
            <p>
              The User using Services offered by Golden Gaming Portal provides
              their consent of not copying, modifying the technology and
              software associated with Golden Gaming. Further, the User accepts
              not to rent, loan, sell, assign, distribute, reverse engineer,
              grant a security interest in, or otherwise transfer any right to
              anyone of the associated technologies and software.
            </p>
            <p>
              These Terms and Conditions binds the User to neither modify nor
              become a cause of modification of any of the files, technology or
              software associated with Golden Gaming.
            </p>
            <p>
              The User provides their acceptance to not disrupt, overburden, or
              promote any kind of disruption or overburdening to the servers or
              computers offering or supporting Golden Gaming Services or the
              enjoyment of GoldenGames24x7.com Services by other User.
            </p>
            <p>
              The User shall neither support or conduct any sort of attack
              including (but not limited to) distribution of a virus or computer
              contaminants (as defined in the Information Technology Act, 2000
              or such other laws in force in India at the relevant time) which
              may interrupt, destroy, limit the functionality or disrupt any
              software, hardware or other equipment belonging to us or that aids
              in providing the Services offered by Golden Gaming or which shall
              restrict the use or enjoyment of other Golden Gaming users.
            </p>
            <p>
              Any form of fraudulent activity including, attempting to use or
              using any other person's credit card(s), debit cards, net-banking
              usernames, passwords, authorization codes, prepaid cash cards,
              mobile phones for adding cash to your user account is strictly
              prohibited.
            </p>
            <p>
              Winnings, bonuses and prizes are unique to the player and are
              non-transferable. In the event you attempt to transfer any
              winnings, bonuses or prizes, these will be forfeited.
            </p>
            <p>
              The User provides their acceptance for not using the Golden Gaming
              platform for any sort of unlawful or unethical activities
              including (but not limited to):
            </p>
            <ul className="ps-3 ps-md-4 mb-3">
              <li>
                - To engage in any obscene, offensive, indecent, racial,
                communal, anti-national, objectionable, defamatory or abusive
                action or communication;
              </li>
              <li>
                - To harass, stalk, threaten, or otherwise violate any legal
                rights of other individuals;
              </li>
              <li>
                - To advertise, offer or sell any goods or services for any
                commercial purpose on GoldenGames24x7.com without the express
                written consent of Golden Gaming; and
              </li>
              <li>
                - To transmit content regarding services, products, surveys,
                contests, pyramid schemes, spam, unsolicited advertising or
                promotional materials, or chain letters.
              </li>
              <li>
                - The User shall not post any material or comment, on any media
                available for public access, which in our sole discretion, is
                defamatory or detrimental to our business interests,
                notwithstanding the fact that such media is not owned or
                controlled by us. In addition to any other action that we may
                take pursuant to the provision hereof, we reserve the right to
                remove any and all material or comments posted by you and
                restrict your access to any media available for public access
                that is either controlled or moderated by us; when in our sole
                opinion, any such material or comments posted by you is
                defamatory or detrimental to our business interests.
              </li>
            </ul>

            <h4>11. User Account Suspension</h4>
            <p>
              We may suspend or otherwise put restrictions on your access to the
              Services on the Websites during investigation for any of the
              following reasons:
            </p>
            <ul className="ps-3 ps-md-4 mb-3">
              <li>
                -Suspected violation of Terms or other abuse of your user
                account including collusion, fraud, manipulation or violation of
                fairplay or responsible gaming policies;
              </li>
              <li>- Suspected breach of security of your user account; or</li>
              <li>-If there have been charge-backs on your user account.</li>
              <li>
                -Our decision to suspend or restrict Service or any part thereof
                as we deem appropriate shall be final and binding on you.
              </li>
            </ul>
            <p>
              We may suspend or otherwise put restrictions on your access to the
              Services on the Websites during investigation for any of the
              following reasons:
            </p>
            <ul className="ps-3 ps-md-4 mb-3">
              <li>
                -Suspected violation of Terms or other abuse of your user
                account including collusion, fraud, manipulation or violation of
                fairplay or responsible gaming policies;
              </li>
              <li>- Suspected breach of security of your user account; or</li>
              <li>-If there have been charge-backs on your user account.</li>
              <li>
                -Our decision to suspend or restrict Service or any part thereof
                as we deem appropriate shall be final and binding on you.
              </li>
            </ul>

            <h4>12. Limitation of Liability</h4>
            <p>
              You clearly understand and agree that the Company shall under no
              circumstances (including, without limitation, in contract,
              negligence or other tort), be liable for any direct, indirect,
              ancillary, special, incidental, consequential or exemplary
              damages. This includes, but is not limited to injury, claim, loss
              of data, loss of income, loss of profit or loss of opportunity,
              loss of or damage to property, damages for monetary loss,
              goodwill, use, data or other intangible loss (even if the company
              has been advised of the possibility of such damages), resulting
              from or arising out of the use of or the inability to use the
              service, even if we have been advised or become aware of the
              possibility of such damages or loss or that such loss was
              foreseeable. You agree to indemnify us against any claims in
              respect of any such matter.
            </p>

            <h4>13. Disclaimers</h4>
            <p>
              Golden Gaming is neither affiliated by nor associated with any
              private or government Sports leagues and tournaments, until and
              unless expressly stated by the Company. In addition to this,
              unless otherwise explicity stated, Golden Gaming is not related
              and does not claim any official status with any of the official or
              non-official sports team, league, tournament, game or
              sportsperson.
            </p>
            <p>
              The Services on the Portal and the content present on it are
              provided strictly on "as is" basis with all faults or failings.
              Any representations, warranties, conditions or guarantee
              whatsoever, express or implied (including, without limitation, any
              implied warranty of accuracy, completeness, uninterrupted
              provision, quality, merchantability, fitness for a particular
              purpose or non-infringement) are specifically excluded to the
              fullest extent permitted by law.
            </p>
            <p>
              To the extent permitted under law, neither Golden Gaming nor its
              parent/holding company, subsidiaries, affiliates, directors,
              officers, professional advisors, employees shall be responsible
              for the deletion, the failure to store, the mis-delivery, or the
              untimely delivery of any information or material.
            </p>
            <p>
              Golden Gaming shall not be held responsible for any harm faced due
              to downloading or accessing any information or material, the
              quality of servers, games, products or sites, cancellation of
              competition and prizes. Golden Gaming does not hold any
              responsibility if a User pays for access to one of Golden Gaming's
              Services and the User shall not be provided any refund as a result
              of, any inaccessibility that is caused by Golden Gaming's
              maintenance on the servers or the technology that underlies our
              sites, failures of Golden Gaming's service providers (including
              telecommunications, hosting, and power providers), computer
              viruses, natural disasters or other destruction or damage of our
              facilities, acts of nature, war, civil disturbance, or any other
              cause beyond our reasonable control.
            </p>
            <p>
              The User is solely responsible for any sort of material accessed,
              downloaded or otherwise obtained through Golden Gaming.com and
              holds the sole responsibility for any potential damage to their
              computer system or loss of data that occurs as a result of
              downloading or accessing any such material.
            </p>
            <p>
              Golden Gaming shall make best endeavours to ensure that the Golden
              Gaming Portal is error-free and secure; however, neither Golden
              Gaming nor any of its partners, licensors or associates makes any
              warranty that:
            </p>
            <ul className="ps-3 ps-md-4 mb-3">
              <li>
                the Golden Gaming.com Portal will meet Users’ requirements;
              </li>
              <li>
                Golden Gaming’s Portal will be uninterrupted, timely, secure, or
                error-free;
              </li>
              <li>
                the results that may be obtained from the use of Golden
                Gaming.com Portal will be accurate or reliable; and
              </li>
              <li>
                the quality of any products, Services, information, or other
                material that Users purchase or obtain through Golden Gaming.com
                Portal will meet Users’ expectations.
              </li>
            </ul>
            <p>
              Golden Gaming holds all the rights to rectify any errors
              identified in the determination of winnings or in the transfer of
              amounts to a User's account, using various method as it deems fit,
              including (but not limited to) through a set-off of the mistaken
              payment from amounts due to the User or deduction from the User's
              account of the amount of mistaken payment. In such events, Golden
              Gaming agrees to notify the user about the error and the
              rectification measure adopted to address the error.
            </p>
            <p>
              Neither Golden Gaming nor its partners, licensors or associates
              disclaims any sort of responsibility for any direct, indirect,
              incidental, special, or consequential damages arising out of the
              use of or inability to use our sites, even if we have been advised
              of the possibility of such damages.
            </p>
            <p>
              Golden Gaming is authorized to cancel any Services, events or
              Contest(s) requiring specific permission or authority from any
              statutory authority or any state or the central government, or the
              board of directors in an event wherein such permission or
              authority is either not obtained or denied either before or after
              the organization of the relevant events or Contest(s).
            </p>
            <p>
              By accepting these Terms and Conditions, the users agree not to
              make any demands, or claims in the event of suspension or closure
              of any Services, events or Contests organized/provided by Golden
              Gaming.
            </p>

            <h4>14. Dispute Resolution, Governing Law and Jurisdiction</h4>
            <p>
              Any sort of disputes or claims associated with Services provided
              by Golden Gaming including the Contest(s), the construction,
              validity, interpretation and enforceability of these Terms and
              Conditions, or the rights and obligations of the User(s) or Golden
              Gaming shall be subject to exclusive jurisdiction of the courts of
              competent jurisdiction at Gangtok, Sikkim. All disputes, issues,
              and questions shall be interpreted by the court of competent
              jurisdiction in accordance with the laws of Republic of India.
            </p>

            <h4>15. Grievance Redressal</h4>
            <p>
              If you have any complaint, grievance or concern, at the first
              instance you should contact our customer support team on
              support@goldengames24x7.com
            </p>
            <p>
              In case you are not satisfied with the response of our customer
              support team, You can escalate your grievance or complaint to our
              Grievance Redressal Officer Mr. ____ by sending an email to
              cgr@goldengames24x7.com.
            </p>
            <p>
              You are required to include your name, contact details, brief
              nature of the grievance or complaint and a statement that the
              information provided in the email is accurate and correct to the
              best of your knowledge. The Grievance Redressal Officer shall
              respond to Your grievance/complaint within 2-3 working days.
            </p>
          </div>
        </div>
      </main>

      <BeforeLoginFooter />
    </>
  );
}

export default BeforeTerms;
